<style lang="scss" scoped>

 .top-info-title {
    flex-shrink: 0;
    height: 43px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 2px solid rgba(1, 153, 153, 1);
 
    .title-txt {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: rgb(0,0,0,0.8);
      font-weight: bold;
    }
  }

</style>
<template>
  <div class="param-table">
     <div class="top-info-title">
      <div class="title-txt">
        参数描述
      </div>
      <!-- <div
        class="right-tab"
        :class="{ 'select-tab': tableIndex == 1 }"
        @click="changeTable(1)"
      >
        Basic parameters
      </div> -->
    </div>
    <div class="bot-table">
      <div
        class="item"
        v-for="(item, index) in showTable"
        :class="index % 2 != 0 ? 'item-h' : ''"
      >
        <div class="left-para" :class="{ 'left-basic': tableIndex == 1 }">
          {{ item[0] }}
        </div>
        <div class="right-para" :class="{ 'right-basic': tableIndex == 1 }">
          {{ item[1] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableIndex: 0,
    };
  },
  computed: {
    tableData1() {
           let data=[
        ['切口缝宽','0.05-0.1mm'],
        [
          '空行速度','60m/min'
        ],[
          '切割厚度','0.17-3mm 视材料厚度而定'
        ],[
          '最大切割速度','≤16m/min'
        ],[
          '管材加工范围 ','圆管 Φ16-Φ120mm'
        ],[
          '机床定位精度','±0.1mm'
        ],[
          'z轴调整范围','65mm'
        ],[
          'W轴调整范围','360°'
        ],
        [
          'Y轴单次送料长度','1200mm'
        ],[
          '机床重复定位精度 ','±0.1mm/1000mm'
        ]
      ]
      return data;
    },
    basicInfo() {
     let data=[
          ['Operating Temperature Range (°C)','-25~+60'],
          ['Relative Humidity ','0~100% '],
          ['Max. Operating Altitude (m)','4000'],
          ['Cooling Method','Natural Convection'],
          ['User Interface','LED, LCD (Optional)'],
          ['Communication','WiFi, RS485 or LAN or 4G or DI (Ripple Control or DRM) (Optional)'],
          ['Communication Protocols ','Modbus-RTU (SunSpec Compliant)'],
          ['Dimension (W×H×D mm)','441×507×210'],
          ['Noise Emission (dB)','<30'],
          ['Topology','Non-isolated'],
          ['Self-consumption at Night (W)','<1'],
          ['Ingress Protection Rating','IP66'],
          ['DC Connector','MC4 (2.5 ~ 4m㎡)'],
          ['AC Connector','AC connector']
        
        ]

      return data;
    },
    showTable() {
      if (this.tableIndex == 1) {
        return this.basicInfo;
      } else {
        return this.tableData1;
      }
    },
  },
  methods: {
    changeTable(index) {
      this.tableIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.param-table {
  width: 100%;
  height: 100%;
  .top-param {
    height: 43px;
    display: flex;
    align-items: center;
    .left-tab,
    .right-tab {
      height: 100%;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      border-bottom: 3px solid transparent;
    }
    .left-tab {
      // width: 125px;
    }
    .right-tab {
      width: 153px;
      margin-left: 32px;
    }
    .select-tab {
      font-weight: 700;
      color: rgba(0, 0, 0, 0.8);
      border-bottom: 3px solid rgb(230,0,18);
    }
  }
  .bot-table {
    .item-h {
      background: rgba(1, 153, 153, 0.1);
    }
    .item {
      width: 343px;
      min-height: 28px;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
    }
    .left-para,
    .right-para {
      padding-left: 12px;
      color: rgba(0, 0, 0, 0.8);
      word-break: break-all;
    }
    .left-para {
      width: 132px;
    }
    .right-para {
      width: 169px;
      color: rgba(0, 0, 0, 0.6) !important;
    }
    .left-basic {
      width: 160px;
    }
    .right-basic {
      width: 183px;
    }
  }
}
</style>
