<template>
  <div class="img-content">
    <div class="top-con">
      <!-- <img :src="imgList[shareIndex]" alt="" /> -->
      <img src="../../../assets/project.jpg" alt="" />
    </div>
    <div class="center-link">
      <div class="left-link">
        <img src="../../../assets/logo.png" alt="" />
      </div>
      <div class="right-link" ref="qrCodeUrl"></div>
    </div>
    <div class="bot-text">

        <div class="feature-tab">
            <div v-for="item in featureData" class="feature-item">
              <!-- <div class="feat-icon"><img :src="item.img" alt="" /></div> -->
              <div>
                <div
                  class="feat-text"
                  v-for="(content, index) in item.datas"
                >
                <div class="point"></div>
                  {{ content }}
                </div>
              </div>
            </div>
          </div>
      <!-- <div class="text" v-for="item in featureData"> -->
      <!-- <div class="text-icon"> -->
      <!-- <img :src="item.img" alt="" /> -->
      <!-- </div> -->
      <!-- <p v-for="content in item.datas"> -->
      <!-- {{ content }} -->
      <!-- </p> -->
      <!-- </div> -->
     
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      imgList: [
        require("@/assets/dialog/first.png"),
        require("@/assets/dialog/second.png"),
        require("@/assets/dialog/thrid.png"),
      ],
      meshMap: ["LFP 3000/HV", "LFP 5-10KWH/LV", "LFP 5000"],
      tabMap: ["Features", "Parameter", "Structure"],
    };
  },
  computed: {
       featureData() {
      let data;
        data = [
          {
            datas: [' 自动上料，节省人工。'],
          },
          {
            datas:['尾料盲区小，节约材料，减少工序。']
          },
          {
            datas:['切割速度快，效率高。']
          },{
            datas:['自动定长。']
          },{
            datas:['接渣除尘专利，减少内壁附着。']
          }
        ]
        

      return data;
    },
    shareIndex() {
      return this.meshMap.findIndex(
        (item) => item === this.$store.state.meshType
      );
    },
    
  },
  mounted() {
    this.creatQrCode();
  },
  methods: {
    creatQrCode() {
      let width = this.$refs.qrCodeUrl.offsetWidth;
      let height = this.$refs.qrCodeUrl.offsetHeight;
      let nowHref = window.location.origin;
      let res1 = this.$store.state.selectName.replaceAll(" ", "");
      let res = res1.replace("·", "-");
      new QRCode(this.$refs.qrCodeUrl, {
        text: `${nowHref}/#/`, // 二维码的内容
        width,
        height,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.L,
      });
    },
  },
};
</script>

<style lang="scss" scoped>  .feature-tab {
        margin-top: 6px;
        width: 100%;
        .feature-item {
          display: flex;
          // align-items: center;
        }
        .feat-icon {
          width: 20px;
          height: 20px;
          margin-top: 6px;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .feat-text {
          font-weight: 400;
          font-size: 10px;
          margin-bottom: 9px;
          color: rgba(0, 0, 0, 0.6);
          white-space: nowrap;
          display: flex;
          align-items: center;
          .point{
            width: 3px;
            height: 3px;
            margin-right: 8px;
            border-radius: 50%;
            background: #019999;
          }
        }
      }
.img-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .top-con {
    width: 100%;
    background: linear-gradient(
      180deg,
      #f0f0f0 0%,
      rgba(247, 248, 250, 0.7) 100%
    );
    // border-radius: 6px;
    img {
      width: 100%;
    }
    flex: 1;
  }
  .center-link {
    margin-top: 7px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .left-link {
      width: 177px;
      height: 30px;
      img {
        width: 100%;
        // height: 100%;
      }
    }
    .right-link {
      width: 48px;
      height: 48px;
    }
  }
  .bot-text {
    margin-top: 6px;
    width: 100%;
    height: 138px;
    flex: 1;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);

    .text {
      display: flex;
      margin-top: 10px;
      .text-icon {
        width: 15px;
        height: 15px;
        margin-right: 9px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
</style>
